import React from "react"
import Layout from "../../../templates/layout"
import SEO from "../../../components/seo/seo"
import Hero from "../../../components/hero/hero"
import HaveQuestions from "../../../components/haveQuestions/haveQuestions"
import ExpandingCard from "../../../components/ExpandingCard/ExpandingCard"
import ExpandAllContainer from "../../../components/ExpandingCard/ExpandAllContainer"
import BrandLogo from "../../../images/logos/sarclisa-logo-new.svg";
import QuestionsIcon from "../../../images/icons/icon-questions-sarclisa.svg"
import HeroIcon from "../../../images/icons/icon-faq-sarclisa.png"
import BackToTop from "../../../components/backToTop/BackToTop"
import PrescribingInfo from "../../../components/prescribingInfo/prescribingInfo"

const pageContent = () => (
  <Layout pageid="page-faq">
    <SEO
      title="HCP FAQs for SARCLISA® (isatuximab-irfc) | HCP Site"
      keywords="CareASSIST, Sanofi, SARCLISA® (isatuximab-irfc), HCP Site, Frequently Asked Questions"
      description="Find HCP FAQs about CareASSIST, including questions on SARCLISA financial assistance. See full Prescribing Info"
    />

    <Hero
      brandLogo={BrandLogo}
      headline="Frequently asked questions"
      copy=""
      heroImgClass="hero-icon"
      hasButton={false}
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Frequently Asked Questions Icon"
      brandAlt="Sarclisa logo"
    />

    <section className="content-section mobile-p-t-50">
      <main>
        {/* Block 1 */}
        <div className="white-block sarclisa">
          <h2 className="faq-title">General CareASSIST program questions </h2>
          <ExpandAllContainer>
            <ExpandingCard cardClass="sarclisa"
              mainContent={() => (
                <div className="expanding-card-content header sarclisa">
                  <h2>How can I help my patients enroll in CareASSIST?</h2>
                </div>
              )}
              hiddenContent={() => (
                <div className="expanding-card-content hidden sarclisa">
                  <p>There are 2 ways you can help your patients enroll:</p>
                  <p>
                    <strong>1) Online </strong>
                  </p>
                  <ul>
                    <li>
                    Enroll <a href="https://sanoficareassisthcp.iassist.com/" id="hcp-sarclisa-faqs-enroll-online" target="_blank" rel="noreferrer">online</a> and make sure to complete all requested fields. This will provide an electronic consent  option for patients who are not in your office at the time of enrollment
                    </li>
                  </ul>
                  <p>
                    <strong>2) Via fax</strong>
                  </p>
                  <ul>
                    <li>
                      Download and complete the <a href="../../../pdfs/careassist-program-enrollment-form.pdf" id="hcp-sarclisa-faqs-enroll-form" target="_blank" rel="noreferrer">enrollment form</a> and fax it to 1-855-411-9689
                    </li>
                   
                  </ul>
                </div>
              )}
            />
            <ExpandingCard cardClass="sarclisa"
              mainContent={() => (
                <div className="expanding-card-content header sarclisa">
                  <h2>What are the eligibility requirements for CareASSIST?</h2>
                </div>
              )}
              hiddenContent={() => (
                <div className="expanding-card-content hidden sarclisa">
                  <ul>
                    <li>Patients must be residents of the United States or its territories or possessions </li>
                    <li>
                      Patients must be under the care of a licensed
                      healthcare provider authorized to prescribe, dispense, and
                      administer medication in the United States
                    </li>
                    <li>
                      Patients must be prescribed SARCLISA
                    </li>
                  </ul>
                </div>
              )}
            />
            <ExpandingCard cardClass="sarclisa"
              mainContent={() => (
                <div className="expanding-card-content header sarclisa">
                  <h2>
                    How long are patients eligible for financial assistance
                    support?
                  </h2>
                </div>
              )}
              hiddenContent={() => (
                <div className="expanding-card-content hidden sarclisa">
                  <p>
                    Patients in the Copay Program remain eligible for copay assistance 
                    for the duration of the calendar year in which they were approved 
                    and are automatically re-enrolled as long as they continue to meet 
                    the eligibility criteria of the program. 
                  </p>
                  <p>
                    Patients in the Patient Assistance Program covered by Medicare Part 
                    B remain eligible for the duration of the calendar year for which 
                    they were approved. 
                  </p>
                  <p>
                    Patients in the Patient Assistance Program not covered by Medicare Part
                    B are eligible for 12 months from the date on which they were approved. 
                  </p>
                  <p>
                    To continue to receive support through the Patient Assistance Program, 
                    patients should re-enroll when appropriate. 
                  </p>
                </div>
              )}
            />
            <ExpandingCard cardClass="sarclisa"
              mainContent={() => (
                <div className="expanding-card-content header sarclisa">
                  <h2>
                    How will I learn if a patient qualifies for  CareASSIST services and
                    the specific support I requested?
                  </h2>
                </div>
              )}
              hiddenContent={() => (
                <div className="expanding-card-content hidden sarclisa">
                  <p>
                    Complete CareASSIST enrollment forms are usually processed
                    within 2 business days. Patients and providers will receive
                    notification of their enrollment status via fax or mail.
                    Approved patients will receive a welcome call from a Care Manager.
                  </p>
                  <p>
                  You or your patient can also apply directly for copay support through the Copay Program on our{" "}
                    <a
                      href="https://portal.trialcard.com/sanofi/careassist"
                      target="_blank"
                      rel="noreferrer"
                      id="hcp-sarclisa-faqs-copay-site"
                    >
                      copay site
                    </a>
                    .{" "}
                  </p>
                </div>
              )}
            />
          </ExpandAllContainer>
        </div>
        {/* Block 2 */}
        <div className="white-block sarclisa">
          <h2 className="faq-title">CareASSIST Copay Program questions</h2>
          <ExpandAllContainer>
            <ExpandingCard cardClass="sarclisa"
              mainContent={() => (
                <div className="expanding-card-content header sarclisa">
                  <h2>How do patients apply for the Copay Program?</h2>
                </div>
              )}
              hiddenContent={() => (
                <div className="expanding-card-content hidden sarclisa">
                  <p>
                    Eligible patients can enroll in one of two ways.
                  </p>
                  <div className="fax-mail flex-section">
                    <div className="fax">
                      <h4>CareASSIST enrollment form</h4>
                      <p>By applying through our enrollment form, your patient may be able to take advantage of additional support options. </p>
                    </div>
                    <div className="mail">
                      <h4>Directly through our copay site </h4>
                      <p>
                        Our copay site can provide: 
                      </p>
                      <ul className="p-l-40">
                        <li className="mb-0">A real-time assessment of patient eligibility</li>
                        <li className="mb-0">A digital copay card</li>
                        <li className="mb-0">The ability to upload documentation for reimbursement such as payment receipts, date of service, CMS 1500, UB-04 forms</li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            />
            <ExpandingCard cardClass="sarclisa"
              mainContent={() => (
                <div className="expanding-card-content header sarclisa">
                  <h2>Are there coverage limits for the Copay Program?</h2>
                </div>
              )}
              hiddenContent={() => (
                <div className="expanding-card-content hidden sarclisa">
                  <p>Yes.</p>
                  <ul>
                    <li>
                      There is a program cap of $25,000 per calendar year.
                      Patients are responsible for out-of-pocket costs that
                      exceed this limit
                    </li>
                    <li>
                      The program does not cover or provide support for
                      supplies, procedures, or any physician-related services
                      associated with SARCLISA. General non–product-related
                      copays, coinsurance, and insurance deductibles are not
                      covered
                    </li>
                    <li>
                      In addition, the Copay Program is not valid for
                      prescriptions covered by or submitted for reimbursement
                      under Medicare, Medicaid, VA, DoD, TRICARE, or similar
                      federal or state programs including any state
                      pharmaceutical assistance programs
                    </li>
                  </ul>
                </div>
              )}
            />
          </ExpandAllContainer>
        </div>
        {/* Block 3 */}
        <div className="white-block sarclisa">
          <h2 className="faq-title">
            CareASSIST Patient Assistance Program questions
          </h2>
          <ExpandAllContainer>
            <ExpandingCard cardClass="sarclisa"
              mainContent={() => (
                <div className="expanding-card-content header sarclisa">
                  <h2>
                    What are the eligibility requirements for the
                    Patient Assistance&nbsp;Program?
                  </h2>
                </div>
              )}
              hiddenContent={() => (
                <div className="expanding-card-content hidden sarclisa">
                  <ul>
                    <li>
                      Patients must have no insurance coverage or lack coverage
                      for their prescribed medication. Patients with Medicare Part
                      B and no supplemental coverage may also be eligible
                    </li>
                    <li>
                      Patient must have an annual household income that does not
                      exceed the greater of $100,000 or 500% of the current
                      Federal Poverty Level
                    </li>
                  </ul>
                </div>
              )}
            />
            <ExpandingCard cardClass="sarclisa"
              mainContent={() => (
                <div className="expanding-card-content header sarclisa">
                  <h2>How is the medication distributed by&nbsp;CareASSIST?</h2>
                </div>
              )}
              hiddenContent={() => (
                <div className="expanding-card-content hidden sarclisa">
                  <p>
                    Medications are shipped directly to the healthcare
                    provider&rsquo;s office.
                  </p>
                </div>
              )}
            />
          </ExpandAllContainer>
        </div>
      </main>
     
      <PrescribingInfo linkId="hcp-sarclisa-faqs-prescribing-information" link="https://products.sanofi.us/Sarclisa/sarclisa.pdf" hideBoxed="hide-boxed"/>
      <HaveQuestions icon={QuestionsIcon} />
    </section>
    <div className="back-to-top-container">
        <BackToTop />
      </div>
  </Layout>
);

export default pageContent